import * as React from 'react';

import { CompleteSignUpFormValues, SignUpFormValues, UserProfileFormValues } from '../../auth.model';
import { ModalConfig, useAuth } from '../../AuthContext';
import { useOnSubmit } from '../hooks/useOnSubmit.hool';

import { Step1Form } from './Step1Form';
import { Step2Form } from './Step2Form';

export const SignUpForm: React.FC<Pick<ModalConfig, 'onSuccess'>> = ({ onSuccess }) => {
  const { showSignInModal, sendVerificationEmail } = useAuth();
  const onSubmit = useOnSubmit(onSuccess);

  const [step, setStep] = React.useState(1); // Nowy stan dla kroku formularza
  const [formData, setFormData] = React.useState<SignUpFormValues | null>(null); // Dane z pierwszego kroku

  const handleStep1Submit = (values: SignUpFormValues) => {
    setFormData(values);

    setStep(2);
  };

  const handleStep2Submit = (values: UserProfileFormValues) => {
    if (!formData) return;
    const completeFormData: CompleteSignUpFormValues = { ...formData, ...values }; // Połącz dane z obu kroków
    onSubmit(completeFormData)
      .then(async () => {
        await sendVerificationEmail(formData.email);
      })
      .catch((e) => {
        console.error('Error signing up', e); // Tu Wyrzuca błąd uprawnień
        showSignInModal();
      });
  };

  return (
    <>
      {step === 1 && <Step1Form onNext={handleStep1Submit} />}
      {step === 2 && <Step2Form onNext={handleStep2Submit} />}
      {/* step 3 w sendVerificationEmail() */}
    </>
  );
};
