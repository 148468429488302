import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { FloatButton } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  saved: boolean;
}

export const SaveButton: React.FC<Props> = ({ saved }) => {
  const { t } = useTranslation();
  return (
    <FloatButton
      shape="square"
      onClick={() => console.log('click')}
      icon={saved ? <CheckOutlined rev={undefined} /> : <LoadingOutlined rev={undefined} />}
      description={
        saved ? (
          <span>{t('FORMS.OFFER_ADD.PLACEHOLDERS.SAVED_PROJECT')}</span>
        ) : (
          <span>{t('FORMS.OFFER_ADD.PLACEHOLDERS.SAVING_CHANGES')}</span>
        )
      }
      type="primary"
      style={{ position: 'fixed', top: '100px', right: '20px', width: '80px', height: '80px', zIndex: 999 }}
      className="rounded-full p-2"
    />
  );
};
