import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

import { Offer } from '../../models/offers.model';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useProcessDates } from './useProcessDates.hook';

export function useGetOffer(id: string): [Offer, boolean, firebase.FirebaseError] {
  const [error, setError] = useState<firebase.FirebaseError>();
  const [loading, setLoading] = useState(false);
  const [offer, setOffer] = useState<Offer>();

  let hasPermission = false;
  try {
    const privateOfferRef = firestore.collection(FirestoreCollection.OFFERS).doc(id);
    privateOfferRef.get().then((snapshot) => {
      if (snapshot.exists) {
        console.log('DEBUG: User has permission to view this offer');
        hasPermission = true;
      }
    });
  } catch (error) {
    console.log('DEBUG: User does not have permission to view this offer');
    hasPermission = false;
  }

  const collection = hasPermission ? FirestoreCollection.OFFERS : FirestoreCollection.OFFERS_PUBLIC;

  useEffect(() => {
    setLoading(true);
    setError(null);
    const unsubscribe = firestore
      .collection(collection)
      .doc(id)
      .onSnapshot(
        (snapshot) => {
          const data = snapshot.data();
          setOffer(
            data
              ? ({
                  ...snapshot.data(),
                  id,
                  ...useProcessDates({
                    availability: data.availability,
                    availabilityTo: data.availabilityTo,
                    offerValidTo: data.offerValidTo,
                  }),
                } as Offer)
              : null
          );
          setLoading(false);
        },
        (e) => {
          console.log('Error', e);
          setError(e);
          setLoading(false);
        }
      );
    return () => unsubscribe();
  }, [id]);
  return [offer, loading, error];
}
