import React, { Suspense } from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../routing.model';

interface PrivateRouteProps extends RouteProps {
  redirectPath?: string;
  suspense?: boolean;
}

export const AdminRoute: React.FC<PrivateRouteProps> = ({ redirectPath, render, suspense, ...rest }) => {
  const { isAdmin, isAuth, isAuthenticating } = useAuth();

  return isAuthenticating ? null : (
    <Route
      {...rest}
      render={
        !isAdmin || !isAuth
          ? () => (
              <Redirect
                to={{
                  pathname: redirectPath || AppRoutes.HOME,
                  state: { from: rest.location },
                }}
              />
            )
          : (...props) => (suspense ? <Suspense fallback={null}>{render(...props)}</Suspense> : render(...props))
      }
    />
  );
};
