import { useEffect, useState } from 'react';

import { useAuth } from '../../features/Auth/AuthContext';
import { getInitialValues } from '../../features/OfferAdd/hooks/getInitialValues';
import { Offer } from '../../models/offers.model';
import { firebaseFunctions, firestore } from '../firebase';
import { FirebaseCallableFunctions, FirestoreCollection } from '../firebase.models';

import { setProjectOffer } from './setProjectOffer';
import { useProcessDates } from './useProcessDates.hook';

export function useGetProjectOffer(id: string): { offer?: Offer; error?: string; hasPermission?: boolean } {
  const [offer, setOffer] = useState<Offer>();
  const [error, setError] = useState<string>();
  const { currentUser, isAuthenticating } = useAuth();
  const [hasPermission, setHasPermission] = useState<boolean>(undefined);

  useEffect(() => {
    firestore
      .collection(FirestoreCollection.OFFERS)
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          console.log('DEBUG: User has permission to view this offer');
          setHasPermission(true);
        } else {
          console.log('DEBUG: User does not have permission to view this offer');
          setHasPermission(false);
        }
      })
      .catch(() => {
        console.log('ERROR: User does not have permission to view this offer');
        setHasPermission(false);
      });
  }, [id, currentUser, isAuthenticating]);

  useEffect(() => {
    if (isAuthenticating) return;
    if (hasPermission === undefined) return;

    const uid = currentUser?.uid;

    const docRef = firestore
      .collection(hasPermission ? FirestoreCollection.OFFERS : FirestoreCollection.OFFERS_PUBLIC)
      .doc(id);

    docRef
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const data = snapshot.data();
          setOffer({
            ...data,
            id,
            ...useProcessDates({
              availability: data.availability,
              availabilityTo: data.availabilityTo,
              offerValidTo: data.offerValidTo,
            }),
          } as Offer);
        } else {
          const newOffer = {
            ...getInitialValues(),
            id: id,
            uid: uid,
          } as Offer;
          const docRef = firestore.collection(FirestoreCollection.OFFERS).doc(id);
          docRef
            .set(newOffer)
            .then(() => {
              setOffer(newOffer);
              setProjectOffer(newOffer);
              const onCreateOffer = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_CREATE_OFFER);
              onCreateOffer({ offerId: id, uid: uid });
            })
            .catch((e) => {
              console.log('Error creating new document', e);
              setError('Wystąpił błąd podczas tworzenia nowego dokumentu. Nie masz do tego uprawnień.');
            });
          setOffer(newOffer);
        }
      })
      .catch((e) => {
        console.log('Error', e);
        setError('Wystąpił błąd podczas pobierania danych. Nie masz do niego dostępu.');
      });
  }, [isAuthenticating, currentUser, id, hasPermission]);

  console.log('OFFER', offer);

  return { offer, error, hasPermission };
}
