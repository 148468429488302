import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { SlickCarousel } from '../../../components/SlickCarousel/SlickCarousel';
import { SlideWrapper } from '../../../components/SlickCarousel/styles';
import { useGetCompanyData } from '../../../firebase/hooks/getCompanyData.hook';
import { useGetOffers } from '../../../firebase/hooks/getOffers.hook';
import { useUserData } from '../../../firebase/hooks/useUserData.hook';
import { ItemListTypes } from '../../../models/googleAnalytics.model';
import { SearchFormFilters } from '../../../models/offers.model';
import { useAuth } from '../../Auth/AuthContext';

interface Props {
  slides?: number;
  filters?: SearchFormFilters;
  sectionTitle?: string;
}

export const OffersSection: React.FC<Props> = ({ slides = 3, filters, sectionTitle }) => {
  const [offers, onSearchOffers, loading] = useGetOffers();
  const [slidesToShow, setSlidesToShow] = useState(slides);
  const [showDots, setShowDots] = useState(true);
  const { t } = useTranslation();
  const { currentUser } = useAuth();
  const [user] = useUserData(currentUser?.uid);
  const { companyData } = useGetCompanyData(currentUser?.uid);
  const [favorites, setFavorites] = useState<string[]>([]);

  const prevFiltersRef = useRef<SearchFormFilters | undefined>();

  useEffect(() => {
    if (!currentUser || !user) return;
    setFavorites(user.companyId ? companyData?.favorites : user?.favorites || []);
  }, [user, companyData]);

  useEffect(() => {
    if (JSON.stringify(prevFiltersRef.current) !== JSON.stringify(filters)) {
      onSearchOffers(filters);
      prevFiltersRef.current = filters;
    }
  }, [filters, onSearchOffers]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setSlidesToShow(1);
        setShowDots(false);
      } else {
        setSlidesToShow(slides);
        setShowDots(offers.length > 3);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [slides]);

  if (loading) {
    return null;
  }

  if (!offers.length) {
    return null; // Jeśli nie ma ofert, nie wyświetlamy niczego
  }

  const mapSectionTitleToItemListType = (sectionTitle) => {
    switch (sectionTitle) {
      case t('OFFERS.INTERESTING_OFFERS'):
        return ItemListTypes.INTERESTING_OFFERS;
      case t('OFFERS.WAREHOUSES_NEAR_CITY'):
        return ItemListTypes.WAREHOUSES_NEAR_CITY;
      case t('OFFERS.BUSINESS_SPACE'):
        return ItemListTypes.BUSINESS_SPACE;
      case t('OFFERS.FIND_OFFICE'):
        return ItemListTypes.FIND_OFFICE;
    }
  };

  return (
    <div style={{ width: '95%', alignItems: 'center', margin: '0 auto' }}>
      {sectionTitle && <h2 className="text-center text-xl font-bold pb-4 pt-8 ">{sectionTitle}</h2>}
      <SlickCarousel
        settings={{
          dots: showDots,
          infinite: true,
          speed: 500,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToShow === 3 ? 3 : 1,
        }}>
        {[1, 2, 3, 4].map((i) =>
          offers.map((o) => (
            <SlideWrapper key={i}>
              <OfferCardHorizontal
                offer={o}
                itemListType={mapSectionTitleToItemListType(sectionTitle)}
                favoriteButtonDisabled={favorites?.includes(o.id)}
              />
            </SlideWrapper>
          ))
        )}
      </SlickCarousel>
    </div>
  );
};
