import { FormikHelpers } from 'formik/dist/types';
import i18next from 'i18next';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ToastVariant } from '../../../../components/Toast/toast.model';
import { toastService } from '../../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../../firebase/firebase.models';
import { handleCampaign, useAuth } from '../../AuthContext';
import { NonEmailSignUpFormValues } from '../../auth.model';

export function useOnSubmit(
  onSuccess: () => void
): (values: NonEmailSignUpFormValues, formikHelpers: FormikHelpers<NonEmailSignUpFormValues>) => Promise<any> {
  const { updateInformation, closeModal } = useAuth();
  const { t } = useTranslation();
  const onNewUser = firebaseFunctions.httpsCallable(FirebaseCallableFunctions.ON_NEW_USER);

  const lang = i18next.language;

  const campaignData = handleCampaign();
  console.log(campaignData);

  return useCallback(async (values) => {
    closeModal();
    return await onNewUser({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      uid: values.uid,
      marketingConsent: values.marketingConsent,
      lang: lang,
      campaignData,
    })
      .then(() => {
        toastService.show(t('AUTH.SIGN_UP.API_RESPONSE_SUCCESS'), 'Sukces');
        updateInformation(values.firstName + ' ' + values.lastName);
        onSuccess?.();
      })
      .catch((e) => {
        toastService.show(t(e.message), t('ERROR'), { variant: ToastVariant.ERROR });
      });
  }, []);
}
