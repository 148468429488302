/* eslint-disable react/no-children-prop */
import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AdminHome } from '../Admin/AdminHome';
import FAQ from '../App/FAQ/FAQ';
import { DocumentViewer } from '../Documents/Document';
import { Home } from '../Home/Home';
import { ConfirmIdentity } from '../MojaTablica/Account/Identity/ConfirmIdentity/ConfirmIdentity';
import { LinkCompany } from '../MojaTablica/Account/Identity/LinkCompany/LinkCompany';
import { AccountInfo } from '../MojaTablica/Account/Info/AccountInfo';
import { ConfirmDeleteAccount } from '../MojaTablica/Account/Info/DeleteAccount/ConfirmDeleteAccount';
import { Security } from '../MojaTablica/Account/Security/Security';
import { Notifications } from '../MojaTablica/Advanced/Notifications/Notifications';
import { Report } from '../MojaTablica/Advanced/Report/Report';
import { Support } from '../MojaTablica/Advanced/Support/Support';
import { ActiveOffers } from '../MojaTablica/MyOffers/ActiveOffers';
import { BookedOffers } from '../MojaTablica/MyOffers/BookedOffers';
import { Favorites } from '../MojaTablica/MyOffers/Favorites';
import { SignedOffersLandlord } from '../MojaTablica/MyOffers/SignedOffersLandlord';
import { SignedOffersTenant } from '../MojaTablica/MyOffers/SignedOffersTenant';
import { UnsignedOffers } from '../MojaTablica/MyOffers/UnsignedOffers';
import { IndicateTransferPerson } from '../Offer/IndicateTransferPerson';
import { Offer } from '../Offer/Offer';
import { OfferSubmitConfirm } from '../Offer/OfferSubmitConfirm';
import { OfferSubmitVerify } from '../Offer/OfferSubmitVerify';
import { Deposit } from '../OfferAdd/Deposit/Deposit';
import { OfferAdd } from '../OfferAdd/OfferAdd';
import { OfferAddConfirm } from '../OfferAdd/OfferAddConfirm';
import { OfferAddVerify } from '../OfferAdd/OfferAddVerify';
import { OfferEditConfirm } from '../OfferAdd/OfferEditConfirm';
import { Offers } from '../Offers/Offers';
import { Protocol } from '../Protocol/Protocol';
import { ViewEmail } from '../ViewEmail/ViewEmail';

import { AdminRoute } from './guards/AdminRoute';
import { PrivateRoute } from './guards/PrivateRoute';
import { AppRoutes } from './routing.model';

export const Routing: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path={AppRoutes.HOME} exact render={() => <Home />} />
        <Route path={AppRoutes.OFFERS} exact render={() => <Offers />} />
        {/* <Route path={AppRoutes.RECRUITMENT} exact render={() => <Recruitment />} /> */}
        <PrivateRoute
          path={AppRoutes.ADD_OFFER_VERIFY}
          exact
          render={({ match }) => <OfferAddVerify offerId={match.params.id} />}
        />
        <PrivateRoute
          path={AppRoutes.ADD_OFFER_CONFIRM}
          exact
          render={({ match }) => <OfferAddConfirm offerId={match.params.id} />}
        />
        <PrivateRoute
          path={AppRoutes.EDIT_OFFER}
          exact
          render={({ match }) => (
            <OfferEditConfirm offerId={match.params.id} value={match.params.value} userId={match.params.uid} />
          )}
        />
        <Route path={AppRoutes.ADD_OFFER} exact render={({ match }) => <OfferAdd offerId={match.params.id} />} />
        <PrivateRoute
          path={AppRoutes.OFFER_PREVIEW}
          exact
          render={({ match }) => <Offer id={match.params.id} preview />}
        />
        <Route
          path={AppRoutes.OFFER_SUBMIT_VERIFY}
          exact
          render={({ match }) => <OfferSubmitVerify offerId={match.params.id} />}
        />
        <Route
          path={AppRoutes.OFFER_SUBMIT_CONFIRM}
          exact
          render={({ match }) => <OfferSubmitConfirm offerId={match.params.id} userUid={match.params.userUid} />}
        />
        <Route path={AppRoutes.OFFER} exact render={({ match }) => <Offer id={match.params.id} />} />
        <Route
          path={AppRoutes.EMAIL_VIEW}
          exact
          render={({ match }) => <ViewEmail emailId={match.params.emailId} accessToken={match.params.accessToken} />}
        />
        <Route
          path={AppRoutes.DOCUMENT}
          exact
          render={({ match }) => <DocumentViewer documentPath={match.params.documentPath} />}
        />

        <Route path={AppRoutes.SUPPORT} exact render={() => <Support />} />
        <Route path={AppRoutes.FAQ} exact render={() => <FAQ />} />

        <PrivateRoute path={AppRoutes.MOJA_TABLICA_ACTIVE_OFFERS} exact render={() => <ActiveOffers />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS} exact render={() => <UnsignedOffers />} />
        <PrivateRoute
          path={AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_LANDLORD}
          exact
          render={() => <SignedOffersLandlord />}
        />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_TENANT} exact render={() => <SignedOffersTenant />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_BOOKED_OFFERS} exact render={() => <BookedOffers />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_FAVORITES} exact render={() => <Favorites />} />

        <Route path={AppRoutes.MOJA_TABLICA_ACCOUNT_INFO} exact render={() => <AccountInfo />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_SECURITY} exact render={() => <Security />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY} exact render={() => <ConfirmIdentity />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_LINK_COMPANY} exact render={() => <LinkCompany />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_NOTIFICATIONS} exact render={() => <Notifications />} />
        <PrivateRoute path={AppRoutes.MOJA_TABLICA_REPORT} exact render={() => <Report />} />

        <PrivateRoute
          path={AppRoutes.MOJA_TABLICA}
          render={() => <Redirect to={AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS} />}
        />

        <PrivateRoute
          path={AppRoutes.CONFIRM_DELETE_ACCOUNT}
          exact
          render={({ match }) => <ConfirmDeleteAccount uid={match.params.id} />}
        />

        <PrivateRoute path={AppRoutes.OFFER_DEPOSIT} exact render={({ match }) => <Deposit id={match.params.id} />} />
        <PrivateRoute path={AppRoutes.PROTOCOL} exact render={({ match }) => <Protocol id={match.params.id} />} />

        <PrivateRoute
          path={AppRoutes.INDICATE_TRANSFER_PERSON}
          exact
          render={({ match }) => <IndicateTransferPerson offerId={match.params.id} userUid={match.params.userUid} />}
        />

        <AdminRoute path={AppRoutes.ADMIN} exact render={() => <AdminHome />} />

        <Route path={'/'} render={() => <Redirect to={AppRoutes.HOME} />} />
      </Switch>
    </Router>
  );
};
