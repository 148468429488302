import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const firebaseApp: firebase.app.App = firebase.app();
export const firebaseAuth: firebase.auth.Auth = firebaseApp.auth();
export const firestore = firebaseApp.firestore();
export const firebaseStorage = firebaseApp.storage();
export const firebaseFunctions = firebaseApp.functions('europe-west1');
export const googleProvider = new firebase.auth.GoogleAuthProvider();

if (typeof window !== 'undefined' && 'measurementId' in firebaseConfig) {
  firebase.analytics();
}

// console.log({
//   apiKey: process.env.REACT_APP_API_KEY,
//   authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_APP_ID,
// });

if (process.env.NODE_ENV == 'development') {
  // firebaseFunctions.useEmulator('localhost', 5001);
  // firestore.useEmulator('localhost', 8080);
  // firebaseStorage.useEmulator('localhost', 9199);
  // firebaseAuth.useEmulator('http://localhost:9099');
}
