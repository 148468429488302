import { Alert, Button, Checkbox, DatePicker, Form, Input, Popover, Space, Switch, TimePicker } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PanelContent, PanelWhite } from '../../../components/Layout/styles';
import { ToastVariant } from '../../../components/Toast/toast.model';
import { toastService } from '../../../components/Toast/toast.service';
import { firebaseFunctions } from '../../../firebase/firebase';
import { FirebaseCallableFunctions } from '../../../firebase/firebase.models';
import { useUserData } from '../../../firebase/hooks/useUserData.hook';
import { Offer, ReceptionForm } from '../../../models/offers.model';
import { SHOW_LOGS } from '../../App/App';
import { useAuth } from '../../Auth/AuthContext';
import { RowBox } from '../../OfferAdd/components/OfferInputs';
import { LocalizedLabelWithMultipleLinks } from '../../OfferAdd/utils/LocalizedLabelWithMultipleLinks';
import { AppRoutes } from '../../Routing/routing.model';
import { useInitialReceptionFormValues } from '../hooks/useInitialReceptionFormValues.hook';

interface Props {
  id: string;
  landlord?: boolean;
  offer: Offer;
}

export const ReceptionOfThePremises: React.FC<Props> = ({ id, landlord, offer }) => {
  const [disabled, setDisabled] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [form] = Form.useForm();
  const [dateAlert, setDateAlert] = useState(false);
  const { push } = useHistory();
  const { initialValues, isLoading, receptionTime } = useInitialReceptionFormValues(id, landlord);
  const [isSending, setIsSending] = useState(false);
  const { currentUser } = useAuth();
  const [isPermissionToggleVisible, setIsPermissionToggleVisible] = useState(false);

  const [userData] = useUserData(currentUser.uid);
  const { t } = useTranslation();

  form.setFieldsValue(initialValues);
  const formattedDate = dayjs(offer.receptionTime?.date).format('DD.MM.YYYY');
  const formattedTime = dayjs(offer.receptionTime?.hour).format('HH:mm');
  const protocolUrl = AppRoutes.PROTOCOL.replace(':id', offer.id);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setDisabled(true);
      if (!landlord) {
        form.setFieldsValue({
          date: dayjs(receptionTime?.date),
          hour: dayjs(receptionTime?.hour),
        });
      }
    }
  }, [initialValues, isLoading]);

  const onFormChange = (_, allValues) => {
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate() + offer.unitReceiptDeadline);

    if (allValues.date > maxDate) {
      setDateAlert(true);
    } else setDateAlert(false);
  };

  const onFormSubmit = async (values: any) => {
    setIsSending(true);
    if (values.date) {
      values.date = values.date.toLocaleString('pl-PL', { day: '2-digit', month: '2-digit', year: 'numeric' });
    }
    if (values.hour) {
      values.hour = values.hour.toLocaleString('pl-PL', { hour: '2-digit', minute: '2-digit', hour12: false });
    }
    const data: ReceptionForm = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      date: values.date,
      hour: values.hour,
      authorized: values.authorized,
    };

    const updateReceptionOfThePremise = firebaseFunctions.httpsCallable(
      FirebaseCallableFunctions.UPDATE_RECEPTION_OF_THE_PREMISE
    );

    const response = await updateReceptionOfThePremise({ receptionForm: data, id: id, landlord: landlord });

    if (response.data.status === 'SUCCESS') {
      setDisabled(true);
      setShowAlert(false);
      toastService.show(t('DATA_SAVED'), t('SUCCESS'), { variant: ToastVariant.SUCCESS });
      if (!landlord) {
        // żeby pokazać, że dane są zapisane
        // żeby przejście było bardziej naturalne
        setTimeout(() => {
          push(AppRoutes.OFFER_DEPOSIT.replace(':id', id));
        }, 1000);
      }
    } else {
      SHOW_LOGS && console.error(response.data);
      if (response.data.message == 'user not verified') {
        setShowAlert(true);
        toastService.show(t('USER_DOES_NOT_MEET_CRITERIA'), t('ERROR'), {
          variant: ToastVariant.ERROR,
        });
      } else {
        toastService.show(t('ERROR_SAVING_DATA'), t('ERROR'), { variant: ToastVariant.ERROR });
      }
      setIsSending(false);
      return;
    }
    setIsSending(false);
  };

  const onChange = (checked: boolean) => {
    setIsPermissionToggleVisible(checked);
    if (checked) {
      const userFormData: ReceptionForm = {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        authorized: true,
        date: initialValues?.date,
        hour: initialValues?.hour,
      };
      form.setFieldsValue(userFormData);
    } else {
      const userFormData: ReceptionForm = {
        firstName: initialValues?.firstName,
        lastName: initialValues?.lastName,
        email: initialValues?.email,
        authorized: false,
        date: initialValues?.date,
        hour: initialValues?.hour,
      };
      form.setFieldsValue(userFormData);
    }
  };

  return (
    <PanelWhite
      className="float-left text-left border-2 m-6"
      bodyFill
      header={
        <>
          {' '}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!landlord ? t('AUTHORIZED_RECEIVER') : t('AUTHORIZED_HANDBACK')}

            <Space direction="vertical" style={{ marginLeft: 6, width: 105 }}>
              <Switch
                defaultChecked={currentUser.email == initialValues?.email}
                onChange={onChange}
                checkedChildren={t('IN_PERSON')}
                unCheckedChildren={t('NOT_IN_PERSON')}
                disabled={disabled}
              />
            </Space>
          </div>
        </>
      }>
      {' '}
      <PanelContent>
        {' '}
        {landlord && 'receptionOfThePremise' in offer && (
          <Space direction="vertical" style={{ marginBottom: 20, marginTop: -20, width: '100%' }}>
            <Alert
              message={t('HANDOVER_MESSAGE', { date: formattedDate, time: formattedTime })}
              type="info"
              style={{ width: '100%', textAlign: 'center' }}
            />
          </Space>
        )}
        {showAlert && (
          <Space direction="vertical" style={{ marginBottom: 20, marginTop: -20 }}>
            <Alert message={t('USER_CRITERIA_NOT_MET')} description={t('USER_NOT_VERIFIED')} type="error" showIcon />
          </Space>
        )}
        {disabled && (
          <Space direction="vertical" style={{ marginBottom: 20, marginTop: 2, textAlign: 'center' }}>
            <Alert
              // message={
              // <div>
              //   Gdy kaucja zostanie wpłacona przez najemce na nasze konto bankowe{' '}
              //   <a className="text-blue-500" href={AppRoutes.PROTOCOL.replace(':id', offer.id)}>
              //     protokół zdawczo odbiorczy
              //   </a>{' '}
              //   zostanie odblokowany.
              // </div>
              // }
              // message={t('DEPOSIT_CONFIRMATION_MESSAGE', { url: protocolUrl })}
              message={
                <LocalizedLabelWithMultipleLinks
                  i18nKey="DEPOSIT_CONFIRMATION_MESSAGE"
                  linkPaths={{
                    1: protocolUrl, // Link to support
                  }}
                />
              }
              type="info"
              showIcon
            />
          </Space>
        )}
        <Form form={form} onValuesChange={onFormChange} onFinish={onFormSubmit} disabled={disabled}>
          <RowBox>
            <Form.Item
              name="firstName"
              label={t('FIRST_NAME')}
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={t('LAST_NAME')}
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input />
            </Form.Item>
          </RowBox>
          <RowBox>
            <Form.Item
              name="email"
              label={t('EMAIL')}
              rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
              <Input style={{ width: '250px' }} />
            </Form.Item>
          </RowBox>

          {!landlord && (
            <>
              <Form.Item
                name="date"
                label={t('PROPERTY_PICKUP_DATE')}
                rules={[
                  { required: true, message: t('VALIDATION.FIELD_REQUIRED') },
                  {
                    message: t('LANDLORD_PICKUP_DAYS', { days: offer.unitReceiptDeadline }),
                    validator: () => {
                      if (!dateAlert) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject();
                      }
                    },
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.isAfter(new Date())) {
                        return Promise.resolve();
                      }
                      return Promise.reject(t('FUTURE_DATE_REQUIRED'));
                    },
                  },
                ]}>
                <DatePicker />
              </Form.Item>
              <RowBox>
                <Popover
                  placement="right"
                  content={<div style={{ maxWidth: '250px' }}>{t('REMINDER_SIGNED_PROTOCOL')}</div>}>
                  <Form.Item
                    name="hour"
                    label={t('PROPERTY_PICKUP_TIME')}
                    rules={[{ required: true, message: t('VALIDATION.FIELD_REQUIRED') }]}>
                    <TimePicker format="HH:mm" />
                  </Form.Item>
                </Popover>
              </RowBox>
            </>
          )}

          <RowBox>
            <Form.Item
              hidden={isPermissionToggleVisible}
              name="authorized"
              valuePropName="checked"
              label={!landlord ? t('AUTHORIZATION_RECEIVE_CONSENT') : t('AUTHORIZATION_HANDBACK_CONSENT')}
              className="reverse-label"
              rules={[
                {
                  message: t('REQUIRED'),
                  validator: (_, value) => {
                    if (value) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject();
                    }
                  },
                },
              ]}>
              <Checkbox />
            </Form.Item>
          </RowBox>

          <Button type="primary" htmlType="submit" disabled={disabled} loading={isSending}>
            {t('CONFIRM')}
          </Button>
        </Form>
      </PanelContent>
    </PanelWhite>
  );
};
