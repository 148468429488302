import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

import { SHOW_LOGS } from '../../features/App/App';
import { Offer, Verification } from '../../models/offers.model';
import { hashValue } from '../../utils/hash.utils';
import { firestore } from '../firebase';
import { FirestoreCollection } from '../firebase.models';

import { useGetCompanyData } from './getCompanyData.hook';
import { useProcessDates } from './useProcessDates.hook';
import { useUserData } from './useUserData.hook';

export function useGetOffersByUid(uid: string): [Offer[], Offer[], boolean, firebase.FirebaseError | null] {
  const [error, setError] = useState<firebase.FirebaseError | null>(null);
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [favorites, setFavorites] = useState<Offer[]>([]);
  const [favoritesIds, setFavoritesIds] = useState<string[]>([]);
  const { companyData, loadingData } = useGetCompanyData(uid);
  const [user] = useUserData(uid);

  useEffect(() => {
    if (!user) return;
    setFavoritesIds(user.companyId ? companyData?.favorites : user?.favorites || []);
  }, [user, companyData]);

  useEffect(() => {
    if (loadingData) {
      setLoading(true);
      return;
    }

    if (!uid) {
      setLoading(false);
      return;
    }

    const unsubscribeFunctions: (() => void)[] = [];

    const fetchData = async () => {
      try {
        const userDocRef = firestore.collection(FirestoreCollection.USERS).doc(uid);
        const userDoc = await userDocRef.get();

        if (!userDoc.exists) {
          SHOW_LOGS && console.error('User not found:', uid);
          return;
        }

        const userData = userDoc.data();
        if (userData?.verification?.company === Verification.ACCEPTED && userData?.companyId) {
          if (!companyData) {
            SHOW_LOGS && console.error('Company not found:', userData.companyId);
            return;
          }

          unsubscribeFunctions.push(
            fetchCompanyOffers(companyData.offers || [], setOffers),
            fetchOffersByCompanyId(companyData.id, setOffers),
            fetchCompanyOffers(favoritesIds, setFavorites)
          );
        } else {
          const offersId = userData.offers || [];
          unsubscribeFunctions.push(
            fetchCompanyOffers(offersId, setOffers),
            fetchCompanyOffers(favoritesIds, setFavorites)
          );
        }
      } catch (err) {
        SHOW_LOGS && console.error('Error fetching offers:', err);
        setError(err as firebase.FirebaseError);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Cleanup na odmontowanie
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }, [uid, companyData, loadingData, favoritesIds, user]);

  const fetchCompanyOffers = (
    offerIds: string[],
    setState: React.Dispatch<React.SetStateAction<Offer[]>>
  ): (() => void) => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    if (!offerIds.length) return () => {};

    const chunkSize = 10;
    const chunks = [];

    for (let i = 0; i < offerIds.length; i += chunkSize) {
      chunks.push(offerIds.slice(i, i + chunkSize));
    }

    const unsubscribes = chunks.map((chunk) =>
      firestore
        .collection(FirestoreCollection.OFFERS_PUBLIC)
        .where('id', 'in', chunk)
        .onSnapshot((snapshot) => {
          const chunkOffers = snapshot.docs.map((doc) => mapOffer(doc));
          setState((prevOffers) => {
            // Aktualizacja stanu z zachowaniem unikalnych ofert
            const updatedOffers = [
              ...prevOffers.filter((o) => !chunkOffers.some((co) => co.id === o.id)),
              ...chunkOffers,
            ];
            return updatedOffers;
          });
        })
    );

    return () => unsubscribes.forEach((unsubscribe) => unsubscribe());
  };
  const fetchOffersByCompanyId = (
    companyId: string,
    setState: React.Dispatch<React.SetStateAction<Offer[]>>
  ): (() => void) => {
    const hashedCompanyId = hashValue(companyId);

    const unsubscribe = firestore
      .collection(FirestoreCollection.OFFERS_PUBLIC)
      .where('offerStatus.tenant.companyId', '==', hashedCompanyId)
      .onSnapshot((snapshot) => {
        const companyOffers = snapshot.docs.map((doc) => mapOffer(doc));
        setState((prevOffers) => {
          // Aktualizacja stanu z zachowaniem unikalnych ofert
          const updatedOffers = [
            ...prevOffers.filter((o) => !companyOffers.some((co) => co.id === o.id)),
            ...companyOffers,
          ];
          return updatedOffers;
        });
      });

    return unsubscribe;
  };

  const mapOffer = (doc: firebase.firestore.DocumentSnapshot): Offer => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      ...useProcessDates({
        availability: data?.availability,
        availabilityTo: data?.availabilityTo,
        offerValidTo: data?.offerValidTo,
      }),
    } as Offer;
  };
  return [offers, favorites, loading, error];
}
