import firebase from 'firebase/app';
export const generateApprovalUrl = async (
  accepted: boolean,
  offerId: string,
  currentUser: firebase.User
): Promise<string> => {
  if (!offerId || !currentUser) {
    throw new Error('Brak wymaganych danych do wygenerowania URL.');
  }

  try {
    const isEmulator = ['localhost', '127.0.0.1'].includes(window.location.hostname);

    const baseUrl = isEmulator
      ? `http://localhost:5001/fast-letting-local-2021/europe-west1`
      : `https://europe-west1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net`;

    const idToken = await currentUser.getIdToken();
    return `${baseUrl}/confirmOrDecline?offerId=${offerId}&userId=${currentUser.uid}&accepted=${accepted}&token=${idToken}`;
  } catch (error) {
    console.error('Error generating approval URL:', error);
    throw new Error('Failed to generate approval URL');
  }
};
