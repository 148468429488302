import { Card, Spin, Table } from 'antd';
import React from 'react';

import { useGetCallersStatistics } from '../hooks/useGetCallersStatistics.hook';
import { CallerData } from '../models/statistics.model';

const calculateStatistics = (callers: CallerData[]) => {
  return callers.map((caller) => {
    const totalOffersSent = Object.keys(caller.newUsers).length + Object.keys(caller.existingUsers).length;
    const totalLinksOpened =
      Object.values(caller.newUsers).filter((u) => u.linkOpened).length +
      Object.values(caller.existingUsers).filter((u) => u.linkOpened).length;
    const totalUserSignups = Object.values(caller.newUsers).filter((u) => u.userSignedUp).length;
    const totalSentAgain = Object.values(caller.existingUsers).reduce((acc, user) => acc + (user.sentAgain || 0), 0);

    return {
      caller: caller.caller,
      totalOffersSent,
      totalLinksOpened,
      totalUserSignups,
      totalSentAgain,
    };
  });
};

export const CallersStatistics: React.FC = () => {
  const data = useGetCallersStatistics();

  console.log('Data:', data);

  if (!data) return <Spin />;

  const statistics = calculateStatistics(data);

  // Konwersja danych do wyświetlenia w tabeli
  const campaignDetails = data.flatMap((caller) =>
    Object.entries({ ...caller.newUsers, ...caller.existingUsers }).map(([, entry]) => ({
      caller: caller.caller,
      offer: entry.campaignData.utmId,
      linkOpened: entry.linkOpened ? '✅' : '❌',
      userSignedUp: entry.userSignedUp ? '✅' : '❌',
      sentAgain: entry.sentAgain || 0,
      campaign: entry.campaignData.utmCampaign,
      utmTerm: entry.campaignData.utmTerm,
      medium: entry.campaignData.utmMedium,
    }))
  );

  const columnsStats = [
    { title: 'Caller', dataIndex: 'caller', key: 'caller' },
    { title: 'Total Offers Sent', dataIndex: 'totalOffersSent', key: 'totalOffersSent' },
    { title: 'Links Opened', dataIndex: 'totalLinksOpened', key: 'totalLinksOpened' },
    { title: 'User Signups', dataIndex: 'totalUserSignups', key: 'totalUserSignups' },
    { title: 'Resent Offers', dataIndex: 'totalSentAgain', key: 'totalSentAgain' },
  ];

  const columnsDetails = [
    { title: 'Caller', dataIndex: 'caller', key: 'caller' },
    { title: 'Offer', dataIndex: 'offer', key: 'offer' },
    { title: 'Link Opened', dataIndex: 'linkOpened', key: 'linkOpened' },
    { title: 'User Signed Up', dataIndex: 'userSignedUp', key: 'userSignedUp' },
    { title: 'Resent', dataIndex: 'sentAgain', key: 'sentAgain' },
    { title: 'Campaign', dataIndex: 'campaign', key: 'campaign' },
    { title: 'Caller Id', dataIndex: 'utmTerm', key: 'utmTerm' },
    { title: 'Medium', dataIndex: 'medium', key: 'medium' },
  ];

  return (
    <div style={{ padding: 20 }}>
      <Card title="Callers Summary">
        <Table dataSource={statistics} columns={columnsStats} rowKey="callerId" />
      </Card>

      <Card title="Campaign Details" style={{ marginTop: 20 }}>
        <Table dataSource={campaignDetails} columns={columnsDetails} rowKey="offerId" />
      </Card>
    </div>
  );
};
