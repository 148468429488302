// SearchedOffers.tsx
import { Pagination } from 'antd'; // <-- import z antd
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animation, FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

import { Article, WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { useGetCompanyData } from '../../../firebase/hooks/getCompanyData.hook';
import { useUserData } from '../../../firebase/hooks/useUserData.hook';
import { ItemListTypes } from '../../../models/googleAnalytics.model';
import { Offer } from '../../../models/offers.model';
import { useAuth } from '../../Auth/AuthContext';

const { Collapse } = Animation;

const StyledArticle = styled(Article)``;

interface Props {
  offers: Offer[];
  isSearchPending: boolean;
  onLocationClick: (id: string) => void;
  onPageChange?: (page: number, pageSize: number) => void;
  totalOffers: number;
}

export const SearchedOffers: React.FC<Props> = ({ offers, onLocationClick, onPageChange, totalOffers }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>();
  const { currentUser } = useAuth();
  const [user] = useUserData(currentUser?.uid);
  const { companyData } = useGetCompanyData(currentUser?.uid);
  const [favorites, setFavorites] = useState<string[]>([]);
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 9;

  useEffect(() => {
    if (!currentUser || !user) return;
    setFavorites(user.companyId ? companyData?.favorites : user?.favorites || []);
    console.log('favorites:', favorites);
  }, [user, companyData]);

  useEffect(() => {
    setIsCollapsed(!!offers);
  }, [offers, setIsCollapsed]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    onPageChange?.(page, pageSize);
  };

  return (
    <Collapse in={isCollapsed}>
      {(props, ref) => (
        <div {...props} ref={ref}>
          <StyledArticle>
            <WidthWrapper>
              <div className="p-2 mx-auto max-w-screen-lg">
                <Typography.H6 className="px-2">
                  {t('NUMBER_OF_PREMISES')} {offers?.length}
                </Typography.H6>
                <FlexboxGrid className="flex justify-center md:justify-start">
                  {offers?.map((o) => (
                    <FlexboxGrid.Item key={o.id}>
                      <OfferCardHorizontal
                        offer={o}
                        onLocationClick={onLocationClick}
                        itemListType={ItemListTypes.SEARCH_OFFERS}
                        favoriteButtonDisabled={favorites?.includes(o.id)}
                      />
                    </FlexboxGrid.Item>
                  ))}
                </FlexboxGrid>
                <div className="flex justify-center mt-4">
                  <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    onChange={handlePaginationChange}
                    total={totalOffers}
                  />
                </div>
              </div>
            </WidthWrapper>
          </StyledArticle>
        </div>
      )}
    </Collapse>
  );
};
