import {
  AppstoreOutlined,
  AuditOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  EditOutlined,
  HeartOutlined,
  HomeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu, type MenuProps } from 'antd';
import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutes } from '../../Routing/routing.model';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group' | 'divider'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const checkPasswordSignInMethod = async (email: string): Promise<boolean> => {
  try {
    const signInMethods = await firebase.auth().fetchSignInMethodsForEmail(email);
    return signInMethods.includes(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD);
  } catch (error) {
    console.error('Error fetching sign-in methods:', error);
    return false;
  }
};

type Props = {
  onClick: MenuProps['onClick'];
  defaultSelectedKeys: string[];
  defaultOpenKeys: string[];
};

export const MenuItems: React.FC<Props> = ({ onClick, defaultSelectedKeys, defaultOpenKeys }) => {
  const [accountSubItems, setAccountSubItems] = useState<MenuItem[]>([]);
  const { t } = useTranslation();

  const accountSubItemsBase = [
    getItem(t('WORKSPACE_MENU.VERIFICATION'), 'sub3', null, [
      getItem(t('WORKSPACE_MENU.CONFIRM_IDENTITY'), AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY),
      getItem(t('WORKSPACE_MENU.CONNECT_WITH_COMPANY'), AppRoutes.MOJA_TABLICA_LINK_COMPANY),
    ]),
    getItem(t('WORKSPACE_MENU.ACCOUNT_INFORMATION'), AppRoutes.MOJA_TABLICA_ACCOUNT_INFO),
  ];

  useEffect(() => {
    const accountSubItemsBase = [
      getItem(t('WORKSPACE_MENU.VERIFICATION'), 'sub3', null, [
        getItem(t('WORKSPACE_MENU.CONFIRM_IDENTITY'), AppRoutes.MOJA_TABLICA_CONFIRM_IDENTITY),
        getItem(t('WORKSPACE_MENU.CONNECT_WITH_COMPANY'), AppRoutes.MOJA_TABLICA_LINK_COMPANY),
      ]),
      getItem(t('WORKSPACE_MENU.ACCOUNT_INFORMATION'), AppRoutes.MOJA_TABLICA_ACCOUNT_INFO),
    ];

    setAccountSubItems(accountSubItemsBase);
  }, [t]);

  useEffect(() => {
    const updateMenuItems = async () => {
      const user = firebase.auth().currentUser;
      if (user) {
        const canSignInWithPassword = await checkPasswordSignInMethod(user.email);
        if (canSignInWithPassword) {
          setAccountSubItems([...accountSubItemsBase, getItem('Bezpieczeństwo', AppRoutes.MOJA_TABLICA_SECURITY)]);
        } else {
          setAccountSubItems(accountSubItemsBase);
        }
      } else {
        setAccountSubItems(accountSubItemsBase);
      }
    };

    updateMenuItems();
  }, []);

  return (
    <Menu
      mode="inline"
      style={{ width: 256 }}
      onClick={onClick}
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      items={[
        getItem(t('WORKSPACE_MENU.PORTFOLIO'), 'sub1', <HomeOutlined rev={undefined} />, [
          getItem(
            t('WORKSPACE_MENU.PROJECTS'),
            AppRoutes.MOJA_TABLICA_UNSIGNED_OFFERS,
            <EditOutlined rev={undefined} />
          ),
          getItem(
            t('WORKSPACE_MENU.OFFERS'),
            AppRoutes.MOJA_TABLICA_ACTIVE_OFFERS,
            <CheckCircleOutlined rev={undefined} />
          ),
          getItem(t('WORKSPACE_MENU.LEASE_AGREEMENTS'), 'sub5', <AuditOutlined rev={undefined} />, [
            getItem(t('WORKSPACE_MENU.LANDLORD'), AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_LANDLORD),
            getItem(t('WORKSPACE_MENU.TENANT'), AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_TENANT),
          ]),
          { type: 'divider' },
          getItem(
            t('WORKSPACE_MENU.RESERVED'),
            AppRoutes.MOJA_TABLICA_BOOKED_OFFERS,
            <CarryOutOutlined rev={undefined} />
          ),
          getItem(t('WORKSPACE_MENU.FAVORITES'), AppRoutes.MOJA_TABLICA_FAVORITES, <HeartOutlined rev={undefined} />),
        ]),

        getItem(t('WORKSPACE_MENU.ACCOUNT'), 'sub2', <AppstoreOutlined rev={undefined} />, accountSubItems),

        { type: 'divider' },

        getItem(t('WORKSPACE_MENU.ADVANCED'), 'sub4', <SettingOutlined rev={undefined} />, [
          // getItem(t('WORKSPACE_MENU.SUPPORT'), AppRoutes.MOJA_TABLICA_SUPPORT),
          getItem(t('WORKSPACE_MENU.REPORT_ISSUE'), AppRoutes.MOJA_TABLICA_REPORT),
        ]),

        getItem(
          t('WORKSPACE_MENU.INFORMATION'),
          'grp',
          null,
          [
            getItem(t('WORKSPACE_MENU.LEGAL_ASPECTS'), '1'),
            getItem(t('WORKSPACE_MENU.TERMS'), '2'),
            getItem(t('WORKSPACE_MENU.TERMS_LANDLORD'), '3'),
            getItem(t('WORKSPACE_MENU.TERMS_TENANT'), '4'),
            getItem(t('WORKSPACE_MENU.PRICING'), '5'),
          ],
          'group'
        ),
      ]}
    />
  );
};
