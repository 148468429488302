import { useEffect, useState } from 'react';

import { firebaseStorage } from '../firebase/firebase';

const cache: Record<string, string[]> = {};

export function useGetOfferImages(id: string, imageNo?: number, small?: boolean): string[] {
  const [imagesUrl, setImagesUrl] = useState<string[]>([]);
  const cacheKey = `${id}-${imageNo}-${small}`;

  useEffect(() => {
    if (!id) {
      setImagesUrl([]);
      return;
    }

    if (cache[cacheKey]) {
      setImagesUrl(cache[cacheKey]);
      return;
    }

    let isCancelled = false;

    (async () => {
      try {
        const path = small ? `offers/${id}/images/small` : `offers/${id}/images`;
        const listResult = (await firebaseStorage.ref(path).listAll()).items;
        const urls = imageNo !== undefined ? await getImage(listResult, imageNo) : await getAllImages(listResult);

        if (!isCancelled) {
          cache[cacheKey] = urls;
          setImagesUrl(urls);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
        if (!isCancelled) {
          setImagesUrl([]);
        }
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, [cacheKey]); // użyj cacheKey jako zależności

  return imagesUrl;
}

async function getAllImages(listResult: any[]): Promise<string[]> {
  try {
    const images: string[] = await Promise.all(listResult.map((imageRef) => imageRef.getDownloadURL()));
    return images;
  } catch (error) {
    console.error('Error fetching all images:', error);
    return [];
  }
}

async function getImage(listResult: any[], imageNo: number): Promise<string[]> {
  try {
    if (!listResult.length || imageNo >= listResult.length) return [];
    const url = await listResult[imageNo].getDownloadURL();
    return [url];
  } catch (error) {
    console.error('Error fetching single image:', error);
    return [];
  }
}
