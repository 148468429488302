import { Button, Card, Form, Space, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RejectProtocolModal } from '../../components/OfferCard/components/RejectProtocolModal';
import { Offer, PostSigningStatus } from '../../models/offers.model';
import {
  Equipment,
  Level,
  LocalMeterReadings,
  ProtocolData,
  TransferredKeysAndDevices,
} from '../../models/protocol.model';
import { useAuth } from '../Auth/AuthContext';

import EstablishedEquipmentState from './Components/EstablishedEquipmentState';
import EstablishedPremiseCondition from './Components/EstablishedPremiseCondition';
import LocalMeterReadingsComponent from './Components/LocalMeterReadings';
import TransferedKeysAndDevices from './Components/TransferedKeysAndDevices';

interface Props {
  offerId: string;
  o: Offer;
  onFinish: (values: ProtocolData) => void;
  isAddingPending: boolean;
  active: boolean;
  handleApprovalClick: (accepted: boolean) => void;
}

export const ProtocolForm: React.FC<Props> = ({
  offerId,
  o,
  onFinish,
  isAddingPending,
  active,
  handleApprovalClick,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ProtocolData>();
  const [isRejectProtocolModalOpen, setIsRejectProtocolModalOpen] = useState(false);

  const { currentUser } = useAuth();

  const handleTransferedKeysChange = (data: TransferredKeysAndDevices[]) => {
    form.setFieldsValue({
      transferredKeysAndDevices: data,
    });
  };

  const handleLocalMeterReadings = (data: LocalMeterReadings[]) => {
    form.setFieldsValue({
      localMeterReadings: data,
    });
  };

  const handleEstablishedEquipmentStateChange = (data: Equipment[]) => {
    form.setFieldsValue({
      establishedEquipmentState: data,
    });
  };

  const handleEstablishedPremiseConditionChange = (data: Level[]) => {
    form.setFieldsValue({
      establishedPremiseCondition: data,
    });
  };

  const validateOfferData = (): boolean => {
    if (!o.transferOfThePremise && currentUser.uid == o.uid) {
      message.error(t('PROTOCOL.FORM_INCOMPLETE_ERROR'), 0);
      return false;
    } else if (!o.transferOfThePremise && currentUser.uid != o.uid) {
      message.error(t('PROTOCOL.LANDLORD_FILL_ERROR'), 0);
      return false;
    }
    if (!o.receptionTime && currentUser.uid != o.uid) {
      message.error(t('PROTOCOL.RECEPTION_TIME_ERROR'), 0);
      return false;
    } else if (!o.receptionTime && currentUser.uid == o.uid) {
      message.error(t('PROTOCOL.TENANT_FILL_ERROR'), 0);
      return false;
    }
    return true;
  };

  const handleFinish = (values: ProtocolData) => {
    values.transferredKeysAndDevices = form.getFieldValue('transferredKeysAndDevices');
    values.localMeterReadings = form.getFieldValue('localMeterReadings');
    values.establishedEquipmentState = form.getFieldValue('establishedEquipmentState');
    values.establishedPremiseCondition = form.getFieldValue('establishedPremiseCondition');
    values.additionalStatements = form.getFieldValue('additionalStatements') ?? '';
    if (!validateOfferData()) {
      return;
    }
    form.resetFields();
    onFinish(values);
  };

  const toggleRejectProtocolModal = () => setIsRejectProtocolModalOpen((prev) => !prev);

  return (
    <Space direction="vertical" className="sm:w-max md:w-5/6 lg:w-2/3 m-8">
      <div className="text-lg font-semibold">
        {t('PROTOCOL.TITLE')}: {o.name}
      </div>
      <div className="opacity-50">
        {t('PROTOCOL.OFFER_NUMBER')}: {offerId}
      </div>
      <Form form={form} onFinish={handleFinish} layout="vertical" disabled={!active} initialValues={o.protocolData}>
        <EstablishedPremiseCondition
          onChange={handleEstablishedPremiseConditionChange}
          offer={o}
          initialValues={o.protocolData?.establishedPremiseCondition}
        />
        <EstablishedEquipmentState
          onChange={handleEstablishedEquipmentStateChange}
          offer={o}
          initialValues={o.protocolData?.establishedEquipmentState}
        />
        <LocalMeterReadingsComponent
          onChange={handleLocalMeterReadings}
          initialValues={o.protocolData?.localMeterReadings}
        />

        <TransferedKeysAndDevices
          onChange={handleTransferedKeysChange}
          initialValues={o.protocolData?.transferredKeysAndDevices}
        />

        <Card title={t('PROTOCOL.ADDITIONAL_STATEMENTS')} className="mt-4">
          <Form.Item name="additionalStatements">
            <TextArea rows={4} placeholder={t('PROTOCOL.NO_STATEMENTS')} maxLength={1000} />
          </Form.Item>
        </Card>

        {o.offerStatus.postSigningStatus === PostSigningStatus.PROTOCOL_ATTACHED &&
        currentUser.uid != o.protocolData?.protocolSubmitterUid ? (
          <Form.Item shouldUpdate>
            {() => (
              <>
                <Button
                  type="primary"
                  onClick={() => handleApprovalClick(false)}
                  disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
                  className="float-right lg:float-left my-4 mx-2 bg-red-700">
                  {t('PROTOCOL.REJECT')}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleApprovalClick(true)}
                  disabled={form.getFieldsError().some((field) => field.errors.length > 0)}
                  className="float-right lg:float-left my-4">
                  {t('PROTOCOL.SIGN')}
                </Button>
              </>
            )}
          </Form.Item>
        ) : (
          <Form.Item shouldUpdate>
            {() => (
              <>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isAddingPending}
                  disabled={form.getFieldsError().some((field) => field.errors.length > 0) || !active}
                  className="float-right lg:float-left my-4">
                  {t('PROTOCOL.SUBMIT')}
                </Button>
                <Button danger onClick={toggleRejectProtocolModal} className="float-right my-4">
                  {t('OFFER.DROPDOWN.REJECT_PROTOCOL')}
                </Button>
              </>
            )}
          </Form.Item>
        )}
      </Form>
      <RejectProtocolModal
        isModalOpen={isRejectProtocolModalOpen}
        toggleModal={toggleRejectProtocolModal}
        offerId={offerId}
        uid={currentUser?.uid}
      />
    </Space>
  );
};
