import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

import { User } from '../../models/main.model';
import { FirestoreCollection } from '../firebase.models';

export function useUserData(uid: string | null): [User | null, boolean, Error | null] {
  const [data, setData] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!uid) return;
    const unsubscribe = firebase
      .firestore()
      .collection(FirestoreCollection.USERS)
      .doc(uid)
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            setData(snapshot.data() as User);
            setLoading(false);
          } else {
            console.error('No such user!', uid);
            setLoading(false);
            setError(new Error('No such document!'));
          }
        },
        (err) => {
          console.error('Error fetching user data:', err);
          // Bezpośrednie użycie błędu z Firebase
          setError(err);
          setLoading(false);
        }
      );

    return () => unsubscribe();
  }, [uid]);

  return [data, loading, error];
}
