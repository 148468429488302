import { MenuProps, Pagination, Space, Spin } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';

import { WidthWrapper } from '../../../components/Layout/styles';
import { OfferCardHorizontal } from '../../../components/OfferCard/OfferCardHorizontal';
import { Typography } from '../../../components/Typography/Typography';
import { useGetCompanyData } from '../../../firebase/hooks/getCompanyData.hook';
import { useGetOffersByUid } from '../../../firebase/hooks/getOffersByUid.hook';
import { ItemListTypes } from '../../../models/googleAnalytics.model';
import { OfferStatusState } from '../../../models/offers.model';
import { hashValue } from '../../../utils/hash.utils';
import { App } from '../../App/App';
import { useAuth } from '../../Auth/AuthContext';
import { AppRoutes } from '../../Routing/routing.model';
import { MenuWrapper } from '../components/MenuWrapper';
import { additionalNavigation } from '../Menu/AdditionalNavigation';
import { MenuItems } from '../Menu/Menu';

import { StyledArticle } from './MojaTablicaOffers';

export const SignedOffersLandlord: React.FC = () => {
  const { push } = useHistory();
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { companyData } = useGetCompanyData(currentUser.uid);
  const itemsPerPage = 9;
  const onClick: MenuProps['onClick'] = (e) => {
    additionalNavigation(e.key);
    push(e.key);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offers, favorites, loading] = useGetOffersByUid(currentUser.uid);

  const signedOffers = useMemo(() => offers.filter((o) => o.offerStatus.state === OfferStatusState.SIGNED), [offers]);

  const mySignedOffers = useMemo(
    () => signedOffers.filter((o) => o.offerStatus.tenant.companyId === hashValue(companyData.id)),
    [offers]
  );

  const paginatedOffers = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return mySignedOffers.slice(startIndex, endIndex);
  }, [mySignedOffers, currentPage, itemsPerPage]);

  const handlePaginationChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <App>
      <MenuWrapper>
        <MenuItems
          onClick={onClick}
          defaultSelectedKeys={[AppRoutes.MOJA_TABLICA_SIGNED_OFFERS_LANDLORD]}
          defaultOpenKeys={['sub1', 'sub5']}
        />
      </MenuWrapper>

      {loading ? (
        <Spin style={{ margin: '50px' }} />
      ) : mySignedOffers && mySignedOffers.length > 0 ? (
        <StyledArticle>
          <WidthWrapper>
            <Space direction="vertical" style={{ marginLeft: 20 }}>
              <Typography.H6>{t('WORKSPACE_MENU.LEASE_AGREEMENTS')}</Typography.H6>
            </Space>
            <FlexboxGrid>
              {paginatedOffers.map((o, i) => (
                <FlexboxGrid.Item key={o.id + '-' + i} colspan={8}>
                  <OfferCardHorizontal
                    offer={o}
                    state={o.offerStatus.state as OfferStatusState}
                    addToFavoritesButton={false}
                    itemListType={ItemListTypes.CONTRACTS}
                  />
                </FlexboxGrid.Item>
              ))}
            </FlexboxGrid>
            <div className="flex justify-center my-4">
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                onChange={handlePaginationChange}
                total={mySignedOffers?.length}
              />
            </div>
          </WidthWrapper>
        </StyledArticle>
      ) : (
        <Space direction="vertical" style={{ margin: 20 }}>
          {t('PORTFOLIO.NO_SIGNED_LEASE_AGR')}
        </Space>
      )}
    </App>
  );
};
